import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass'
import { Container, Layout } from '../components'
import config from '../utils/siteConfig'

const ListItem = ({ children }) => (
  <Text as="li" pb={4}>
    {children}
  </Text>
)

const PrivacyPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        {data.contentfulPage.title} – {config.siteTitle}
      </title>
      <meta name="description" content={data.contentfulPage.title} />
      <meta
        property="og:title"
        content={`${data.contentfulPage.title} – ${config.siteTitle}`}
      />
      <meta property="og:url" content={config.siteUrl} />
    </Helmet>

    <Box>
      <Container mt={[4, 5, 6]} py={[4, 5]} px={[3, 4]} maxWidth="48em">
        <Heading fontWeight="normal" mb={[3, null, 4, 5]} fontSize={[3, 4, 5]}>
          Tietoturvaseloste
        </Heading>
        <Text fontSize={[1, 2]}>
          <p>
            Yhdistys on sitoutunut suojaamaan nykyisten ja potentiaalisten
            jäsentensä, toimintaan osallistuvien henkilöiden sekä
            verkkopalveluidensa käyttäjien yksityisyyttä kulloinkin voimassa
            olevan tietosuojalainsäädännön mukaisesti.
          </p>
          <Text as="ol" m={0} pl={4}>
            <ListItem>
              <strong>Yhteystiedot:</strong> Astanga Jooga Lappi ry,
              www.astangajoogalappi.net, astanga.lappi@gmail.com
            </ListItem>
            <ListItem>
              <strong>Yhteyshenkilö:</strong> hallituksen puheenjohtaja
            </ListItem>
            <ListItem>
              <strong>Käsiteltävät tiedot:</strong> Yhdistyksen jäseniltä
              kerättäviä tietoja ovat: Etu- ja sukunimi, kotipaikka,
              sähköpostiosoite, puhelinnumero. Yhdistyksen tilaisuuksiin
              osallistuvista henkilöistä kerätään tyypillisesti seuraavia
              tietoja: Etu- ja sukunimi. Yhteystiedot ovat vapaaehtoisesti
              ilmoitettavia ja ne saa pyydettäessä poistettua jäsenrekisteristä.
              Edellä mainitut tiedot kerätään rekisteröidyiltä itseltään. Ilman
              em. tietoja emme voi ylläpitää jäsenyyttä tai toteuttaa
              tilaisuuksia, joihin osallistuvat jäsenet saavat osallistua
              jäsenhintaan.
            </ListItem>
            <ListItem>
              <strong>Käsittelyn tarkoitukset ja oikeusperuste:</strong>{' '}
              Käsittelemme tietoja seuraavia tarkoituksia varten:
              Jäsenyyssuhteen hoitaminen, kuten yhteyshenkilötietojen
              ylläpitäminen, tiedottaminen yhdistyksen toiminnasta ja
              jäsenmaksut. Käsittelemme tällöin jäsenyyteen perustuvan
              asiallisen yhteyden (oikeutetun edun) perusteella. Jäsenrekisteriä
              käsittelee ja ylläpitää yhdistyksen toimihenkilö.
            </ListItem>
            <ListItem>
              <strong>Tietojen säilytysaika:</strong> Mikäli henkilö ei ole
              maksanut jäsenmaksuansa kolmeen vuoteen, poistamme yhteystiedot
              jäsenrekisteristä. Mikäli henkilö irtisanoo jäsenyytensä, hänen
              yhteystietonsa poistetaan jäsenrekisteristä ensi tilassa.
            </ListItem>
            <ListItem>
              <strong>Henkilötietojen luovutukset ja siirrot:</strong>{' '}
              Jäsenrekisteriä ei luovuteta ulkopuolisille.
            </ListItem>
            <ListItem>
              <strong>Rekisteröidyn oikeudet:</strong> Rekisteröity voi
              vaikuttaa tietojen keräämiseen ja käsittelyyn alla kuvatuin
              tavoin: Rekisteröidyllä on oikeus tarkastaa hänestä tallennetut
              henkilötiedot. Rekisteröidyn pyynnöstä myös oikaisemme,
              täydennämme tai poistamme käsittelyn tarkoituksen kannalta
              virheelliset, puutteelliset tai vanhentuneet henkilötiedot.
              Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle,
              mikäli hän katsoo, että hänen tietojaan on käsitelty tämän
              tietosuojalausekkeen ja kulloinkin voimassaolevan lainsäädännön
              vastaisesti. Yhteystiedot löytyvät osoitteesta: www.tietosuoja.fi
            </ListItem>
          </Text>
        </Text>
      </Container>
    </Box>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query privacyQuery {
    contentfulPage(slug: { eq: "yhteystiedot" }) {
      title
      excerpt {
        excerpt
      }
      image {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
